import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';

import SEO from '../../components/seo/seo';
import Layout from '../../components/layout/layout';
import InnerPagesBanner from '../../components/inner-pages-banner/inner-pages-banner';
import Company from '../../components/sub-page-client-company/sub-page-client-company';
import Testimonial from '../../components/sub-page-client-testimonial/sub-page-client-testimonial';
import ClientContent from '../../components/sub-page-client-container/sub-page-client-container';
import SeeMore from '../../components/sub-page-client-see-more/sub-page-client-see-more';
import Swiper from '../../components/sub-page-client-swiper/sub-page-client-swiper';
import ClientImage from '../../components/sub-page-client-image/sub-page-client-image';

export default ( props ) => {

  const innerBannerData = {
    backgroundImg: 
      <BackgroundImage 
        Tag="section"
        className="inner-pages__background"
        fluid={props.data.background_placeholder.childImageSharp.fluid}
      ></BackgroundImage>,
    mainTitle: 'CPS',
    mainText: 'Canon Professional Services'
  };

  const descriptionData = {
    link: 'https://www.canon-europe.com/pro/canon-professional-services/',
    services: ['service1', 'service2', 'service3', 'service4' ],
    logo: <Img alt="canon logo" fluid={props.data.canon_logo.childImageSharp.fluid}/>,
    titleDescription: 'sed do eiusmod tempor incididunt ut labore et',
    description: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    tech:[
      <Img alt="wordpress logo" fixed={props.data.wordpress.childImageSharp.fixed}/>,
      <Img alt="css logo" fixed={props.data.css.childImageSharp.fixed}/>,
      <Img alt="js logo" fixed={props.data.js.childImageSharp.fixed}/>,
      <Img alt="html logo" fixed={props.data.html.childImageSharp.fixed}/>,
      <Img alt="react logo" fixed={props.data.react.childImageSharp.fixed}/>,
      <Img alt="php logo" fixed={props.data.php.childImageSharp.fixed}/>
    ]
  };

  const briefData = {
    section: true,
    title: 'Brief',
    description: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    point: false,
    result: false,
  };

  const swiperData =  [
    <Img 
      alt="placeholder" 
      fluid={props.data.placeholder.childImageSharp.fluid} 
    />,
    <Img 
        alt="placeholder" 
        fluid={props.data.placeholder.childImageSharp.fluid} 
      />
  ];

  const goalsData = {
    section: false,
    title: 'Goals',
    description: false,
    points: [
      'sed do eiusmod tempor incididunt ut labore et do', 
      'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse', 
      ' tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse',
      'sed ut labore et dolore magna aliqua. Quis ipsum suspendisse' 
    ],
    result: false,
  };

  const processData = {
    section: true,
    title: 'Process',
    description: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    point: false,
    result: false,
  };

  const resultImg = 
    <Img 
      alt="placeholder" 
      fluid={props.data.placeholder.childImageSharp.fluid} 
    />
  ;

  const outcomeData = {
    section: false,
    title: 'Outcome',
    description: false,
    point: false,
    result: [ 
      {
        title: 'title1',
        sub: 'sub text'
      }, 
      {
        title: 'title2',
        sub: 'sub text'
      }, 
      {
        title: 'title3',
        sub: 'sub text'
      },
      {
        title: 'title4',
        sub: 'sub text'
      }, 
    ]
  };

  const testimonialData = {
    quote: 'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
    name: 'Fred',
    position: 'full-stack'
  };

  const seeMoreData = {
    links: ['pro','rsmd','usimoney']
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={[`digital`, `agency`, `technology`, `Radium`, `Client`, `cps`]} 
      />
      <InnerPagesBanner {...innerBannerData} />
      <Company {...descriptionData} />
      <ClientContent {...briefData}/>
      <Swiper  images={swiperData}/>
      <ClientContent {...goalsData} />
      <ClientContent {...processData} />
      <ClientImage image={resultImg}/>
      <ClientContent {...outcomeData} />
      <Testimonial {...testimonialData} />
      <SeeMore {...seeMoreData} />
    </Layout>
  )
};

export const cpsQuery = graphql`
  query {

    background_placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder1300x800.png" }) {
      ...fluidNormal
    }

    canon_logo: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "canon-logo.png" }) {
      ...fluidNormal
    }

    wordpress: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "wordpress-logo.png" }) {
      ...thumbnail
    }
  
    css: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "css.png" }) {
      ...thumbnail
    }
  
    php: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "php.png" }) {
      ...thumbnail
    }
  
    react: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "react.png" }) {
      ...thumbnail
    }
  
    html: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "html.png" }) {
      ...thumbnail
    }
  
    js: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "js.png" }) {
      ...thumbnail
    }

    placeholder: file(sourceInstanceName: {eq: "images"}, relativePath: { eq: "placeholder600x400.png" }) {
      ...fluidNormal
    }
  }
`;